@import '../../../../../variables.scss';

.ppa_MgeConstraintType_modal{

    .sb_modal_form{
        padding-top: 35px;
    } 

    .MuiDialog-container{
        .MuiDialog-paper{
            max-width: 650px;
        }
    }

    .modal_form_ActionBtn_row {
        // &:not(.ReadOnlyRow){

        //     .act-icons {
        //         align-self: flex-start;
        //     }
        // }
        label{
            min-height: 24px;
        }
        .custom_label,label{
            display: none;
        }

        // &:first-child{
        //     .custom_label,label{
        //         display: inherit;
        //     }

        // }

    } 
    

}

/*==============  Media Queries  ====================*/


@media (min-width:900px){
    
}