@import '../../../../variables.scss';

.create-object-wrapper {
  background: #f5f5f5;
  padding: 20px;
  margin: 20px 0px;
  border-radius: 5px;
  border: 2px solid transparent;

  &.error {
    border: 2px solid red;
  }

  &.edit {
    border-left: 4px solid #98a6f5;
  }

  .delete-object-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 32px;
    max-height: 80px;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }

    .delete-object-btn {
      font-size: 26px;
      color: #cc2936;
    }
  }
}
