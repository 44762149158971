$body1: 'Open Sans';

$siteBlueDark: #00529b;
$siteBlueLight: #3abde7;
$colorWhite: #fff;
$colorBlack: #000;
$colorGreen: #01d83d;
$colorGreenDrak: #5db96d;
$colorGold: #cdb97d;
$colorOrange: #ef682e;
$borderColor: #e2e2e2;
$altgrayBg: #f4f4ff;
$tablechildevencolor: #f1f1ff;
$bodycolor: #2b2b2b;
$sbBlueLight2: #e8eff6;
$disablebtn: #dcdcdc;
$tablehighlight: #b2afff;
$customscrollthumb: #d2d2d2;
$customscrollthumbhover: #bfbfbf;
$customdialogtableheader: #d2d2d240;
$colorRed: #c00a0a;

$font-light: 300;
$font-reg: 400;
$font-med: 600;
$font-bold: 700;
$font-Exbold: 800;

$fullWidthCol: 100%;
$font16: 16px;
$font18: 18px;
$font24: 24px;
$sbFontAllcaps: uppercase;
$sbFontCapitalize: capitalize;
