@import '../../../variables.scss';


/*========= Component css ========*/

.hogDistForecastPage{

   

    .sb-form-panel-accr{
        margin-bottom: 30px;
    }

    .ppa_hogDistForecast_Filter{
        .search_filter_button{
            .search-btn.Mui-disabled {
                color: $colorWhite;
                box-shadow: none;
              }
        }
    }

    .warehouse_table{
        .common_btn.view_btn{
            background-color: $colorGreenDrak;

            > span {
                display: block;
              }
          
              .MuiSvgIcon-root {
                font-size: 24px;
                line-height: 1;
                margin-top: 6px;
                position: relative;
                color: $colorWhite;
              }
        }
    }


    .ppa_hogDistColWrapper .ppa_innerBox {
        display: flex;       
        gap: 24px;

        .ppa_col_left {
            flex: 1 1 40%;
            min-width: 350px;              
                    
                    @media screen and (min-width:1530px){
                        max-width: 400px;
                       
                    } 
                    @media screen and (max-width:1199px){
                        flex: 1 1 auto;                        
                    }   
                }
          
          .ppa_col_right {
            flex: 1 auto 60%;

                @media screen and (max-width:1199px){
                    flex: 1 1 auto;  
                    width: 100%;
                }   
          }

          @media screen and (max-width:1199px){           
            flex-wrap: wrap;  
        }  
          
    }

   

}    


