@import '../../../variables.scss';

.navigation_item {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 0.25px solid #eeeeee;
    padding: 5px;
    background-color: #fff;

    &:hover .nav_item_content .nav_content_actions .edit_icon {
      opacity: 0.5;
    }

    &:hover .nav_item_content .nav_content_actions .delete_icon {
      opacity: 0.5;
    }

    .nav_item_drag_icons {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      .drag_icon {
        color: $siteBlueLight;
      }

      .arrow_icon {
        color: #666;
        font-weight: normal;
      }
    }

    .nav_item_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;

      .nav_title {
        font-weight: normal;
        text-align: start;
      }

      .nav_content_actions {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .edit_icon {
          color: $siteBlueLight;
          opacity: 0;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }

        .delete_icon {
          color: $colorRed;
          opacity: 0;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.manage-navigation {
  background-color: #fff;
  border-radius: 5px;
  padding: 1.2rem;

  & .manage-nav-content {
    border: 1px solid #cecece;
    border-radius: 5px;

    & .manage-nav-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #cecece;
      padding: 0.6rem;
      gap: 0.5rem;

      & > p {
        font-weight: bold;
        width: 90%;
        text-align: center;
        span {
          font-weight: normal;
          font-size: 0.9rem;
          color: #b2b2b2;
        }
      }
    }

    & .manage-nav-list {
      & .rct-tree-root {
        padding: 0;

        & > div {
          min-height: 92px !important;
          max-height: 460px !important;
          overflow-y: auto;
        }

        div {
          > ul {
            padding: 0 !important;
          }
        }
      }
    }
  }

  & .manage-nav-action {
    margin-top: 1rem;
  }
}

.create-category {
  background-color: #fff;
  border-radius: 5px;

  & .create-cat-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecece;
    padding: 0.6rem 1rem;
    gap: 0.5rem;

    & > p:first-child {
      font-weight: bold;
    }
  }

  & .main_wrapper {
    padding: 1rem;
    & .rct-tree-root {
      padding: 0;

      & > div {
        min-height: 100px !important;
      }

      div {
        > ul {
          padding: 0 !important;
        }
      }
    }

    .edit_icon {
      margin-right: 0.5rem;
    }

    .delete_icon {
      display: none;
    }
  }

  & .create-cat-content {
    padding: 1rem;

    & .create-cat-icon {
      padding-top: 25px !important;

      & .MuiGrid-item {
        padding-top: 0px !important;
      }

      & .icon-grid {
        display: flex;
        gap: 0.5rem;
      }

      & .create-cat-action {
        border: 1px solid #bebebe;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-top: 1.95rem;
        height: 2.9rem;
      }
    }
  }

  .search_filter_button.main_action {
    padding: 1rem !important;
  }
}

.grid_container {
  display: grid;
  grid-template-areas:
    'main main category'
    'main main object';
  gap: 10px;
  // grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1.25fr;
}

.grid_item_1 {
  grid-area: main;
}

.grid_item_2 {
  grid-area: category;
}

.grid_item_3 {
  grid-area: object;
}
