@mixin resetList {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

%DisplayNone {
  display: none;
}

@mixin border-radius($property) {
  -webkit-border-radius: $property;
  -moz-border-radius: $property;
  border-radius: $property;
}

@mixin transform-rotate($angle) {
  -webkit-transform: rotate($angle);
  -moz-transform: rotate($angle);
  -ms-transform: rotate($angle);
  transform: rotate($angle);
}





