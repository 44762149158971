.lop_table {
  :global {

    .table_row {
      & th {
        padding: 0;

        span {
          padding: 12px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 42px;
          border-inline: 1px solid #e2e2e2 !important;
        }
      }

      & td {
        padding: 0 !important;

        span.MuiBox-root {
          padding: 18px !important;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          border-inline: 1px solid #e2e2e2 !important;

          p {
            margin: 2px;
          }
        }
      }

      & th.sticky_cell,
      td.sticky_cell {
        background-color: #fff !important;
        z-index: 1;
      }

      & .custom_component {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .custom_table_body {
      & tr:nth-child(even) td span.MuiBox-root {
        background-color: #f1f1ff !important;
      }
    }

    .detailView {
      color: #00529b;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .detailView:has(p.non-select) {
      color: rgba(0, 0, 0, 0.87);

      &:hover {
        cursor: auto;
        text-decoration: none !important;
      }
    }

  }

}