@import '../../../variables.scss';


/*========= Edit Grid css =========*/

.ppa_hv_editRow_form{

    &.ReadOnlyRow{
            
        .sb_AutoSearchDD{

            .Mui-disabled{
                background-color: #fbfbfb;
                border: 0; 
                padding-left: 0;                  
                .MuiAutocomplete-input{
                        padding-left: 0;
                        border:0;
                        color: $bodycolor;                        
                        
                }                    
    
            }
    
        } 

        .sb_DatePicker .MuiInputBase-root.Mui-disabled{
            background-color: #fbfbfb;
            padding-left: 0;
        }

        .MuiFormControlLabel-root.Mui-disabled{
            background-color: #fbfbfb;
            > .MuiFormControlLabel-label{
                background-color: transparent;
            }
        }
    
        .Mui-disabled{
            // background-color: #fbfbfb;
            // border: 0;
            // padding-left: 0;            
           
            .MuiInputBase-input{
                    padding-left: 0;
                    border:0;
                    color: $bodycolor;
                    -webkit-text-fill-color:$bodycolor;
                    font-size: $font16;
                    opacity: 1;
                    background-color: #fbfbfb;
            }

            .MuiOutlinedInput-notchedOutline,fieldset{
                border: 0;
                outline: 0;
            }

             /* Chrome, Safari, Edge, Opera */
             input[type=number]::-webkit-outer-spin-button,
             input[type=number]::-webkit-inner-spin-button {
             -webkit-appearance: none;
             margin: 0;
             }

             /* Firefox */
             input[type=number] {
                 appearance: textfield;
                 -moz-appearance: textfield;
             }
    
        }   
        
        .btn_disable{
            color: $colorWhite;
           
            &.ppa_edit_btn{
                color: $colorWhite;
            }
        }
       
    }

}

.ppa_hv_editGridForm{

    .ppa_chk_control{
        margin-top: 30px;
    }
    .ppa_HC_editGrid_button{
        padding-top: 16px;
    }


    .btn_disable{       
       
        &.ppa_edit_btn,&.ppa_save_btn{
            color: $colorWhite;
        }
    }
    
    .title-Box{
        text-align: left;
        h4{
            font-size: 20px;
            margin: 0;
            padding: 30px 0;
            position: relative;
            color: $siteBlueDark;
            font-weight: $font-med;
            
            >span{
                display: inline-block;
                background-color: #fbfbfb;
                position: relative;
                z-index: 2;
                padding-right: 20px;
            }

          &::after{
            content: '';
            height: 1px;
            background:$borderColor;
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            bottom: 48%;
            z-index: 1;
          }  
        }
    }
}


/*========= Component css ========*/

.Site_wrapper{

    .ppa_actualTabFilter,
    .plannedTabFilter{

        .title-Box{
            h4{
                font-size: 20px;
                margin: 0;
                padding: 30px 0;
                position: relative;
                color: $siteBlueDark;
                font-weight: $font-med;
                
                >span{
                    display: inline-block;
                    background-color: $colorWhite;
                    position: relative;
                    z-index: 2;
                    padding-right: 20px;
                }
    
              &::after{
                content: '';
                height: 1px;
                background:$borderColor;
                width: 100%;
                display: block;
                position: absolute;
                left: 0;
                bottom: 48%;
                z-index: 1;
              }  
            }
        }
    }

}

.Site_wrapper .plannedTabFilter{

     .PPA_SidePanelOpen{
        animation: changeBTNPos 2000ms ease 500ms;
           >div{
               justify-content: flex-start;
           }
       }
}


.manageKillCutPage{

    .prodlineMsgrow{
        margin-bottom: 0px;
        padding-top: 30px;
        .prodlineButton{
            background-color: #F8F9DB;
            color: $colorBlack;
            margin-right: 20px;
            text-transform: uppercase;
            cursor:pointer;
            pointer-events: none;
            font-weight: $font-med;

          &:hover{
            box-shadow: none;
          }  

            .MuiSvgIcon-root{
                color: $siteBlueDark;
            }
        }

        color: #A6A4A5;
    }

    .plannedTabFilter,.ppa_actualTabFilter{
       
        .search_filter_button{
            .Mui-disabled.MuiButton-contained  {
                color: $colorWhite;
                box-shadow: none;
              }
        }



    }

    .HideLabel{
        label{
            opacity: 0;           
        }
    }

    .sb_tabWarpper {       
        .sbTabs {
          .MuiButtonBase-root {
            min-width: 160px;
          }      
        }
      }

   

}

.Site_wrapper #main-container{
    .manageKillCutPage .warehouse_table {
        
        tbody tr{
            &:nth-child(even) {
                background-color: $colorWhite;
            }
            &:nth-child(odd) {
                background-color: $tablechildevencolor;

                .ppa_hv_editGridForm .title-Box h4 > span{
                    background-color: #fbfbfb;
                }
            }
        }
    }

    .manageKillCutPage{

        /*========== Edit Expended Tr css start here ========*/
   

        .expandTrRow{
             
            td{
                border-top: 2px solid #B2AFFF; 
                border-right-color:transparent;
                border-bottom-color: transparent; 
            }                             

            td:first-child{
                border-left: 1px solid #B2AFFF;
            }

            td:last-child{
                border-right: 1px solid #B2AFFF;
                background-color: #fbfbfb;
                border-bottom-color:#fbfbfb ;
                position: relative;
                z-index: 1;
            }
            .gbl_roundIcon_grp:before{
                content:'';
                display: block;
                position: absolute;
                top:-1px;
                left: 0px;
                height: 1px;
                width: 100%;  
                background-color: #B2AFFF;                  
            }

            .gbl_roundIcon_grp:after{
                content:'';
                display: block;
                position: absolute;
                top:0;
                right: 0px;
                height: 105%;
                width: 4px;  
                background-color: #B2AFFF;                  
            }
    }

    .ToggleTrRow{

        td{
            padding: 0;

            &:first-child{
                border-left: 1px solid #B2AFFF;
            }

            > div{
            border-bottom: 1px solid #B2AFFF;                
            border-right: 4px solid #B2AFFF;
            padding: 20px 15px;
            background-color: #fbfbfb;
            }   
            
            label{
                text-align: left;
                display: block;
            }                
            
        }           

        
    }   

/*========== Edit Expended Tr css ends here ========*/



    }


}


.HV_addtionaFeilds{
        .Mui-disabled{
    
            /* Chrome, Safari, Edge, Opera */
        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }
        /* Firefox */
        input[type=number] {
            appearance: textfield;
            -moz-appearance: textfield;
        }

    } 

}

@keyframes changeBTNPos {
    0% {
     opacity: 0;  
    }
    90% {
     opacity: 1;  
    }
   }
/*=========   Tab Grid  css  =============*/
.manageKillCutPage .ppa_HVGridPenel .sbTabs .MuiButtonBase-root{
            font-size: 18px;
            min-width: 130px;      
}


/*=========  Tab Grid  css  =============*/

.ppa_hv_grid_panel_box{
    .warehouse_table tr th{
        white-space: nowrap;
    }
}


.plannedTabFilter{
    .sb_cus_info{
        display: inline-flex;
        margin: 10px 0;

        .MuiSvgIcon-root{
            color: $siteBlueDark;
        }
    }
}

.title-Box{
    .ppa_SplTotal {
        border: 2px dashed $siteBlueLight;
        padding: 5px 12px;
        border-radius: 4px;
        color: $colorBlack;
        font-size: 16px;
        transform:translate(-50%,-50%);
        position: absolute;
        left: 50%;
        top:50%;
        z-index: 2;
        background-color: $colorWhite; 
        font-weight: 500;
        display: inline-block; 
        text-transform: capitalize;
        
        .MuiSvgIcon-root{
            color:$siteBlueLight;
            vertical-align: middle;
        }

        .ppa_splTotalVal{
            color:$siteBlueLight;
        }


            @media (max-width:767px) {
                    font-size: 12px;
                    transform:translate(0%,-50%);
                    left: auto;
                    right: 0;
            }

            &.ppa_SplDither{
                color: #A6A4A5;
                border: 2px dashed #A6A4A5;               
                .MuiSvgIcon-root{
                    color:#A6A4A5;
                }
        
                .ppa_splTotalVal{
                    color:#A6A4A5;
                }
            }
    } 
}

.ppa_hv_editGridForm{
    .title-Box{
        .ppa_SplTotal{
            background-color: #fbfbfb;
        }
    }

    .ReadOnlyRow {
        .ppa_SplTotal{
            display: none;
        }
    } 
}