@import '../../../../variables.scss';

button.man_role_create_lnk {
  box-shadow: none;
  font-weight: 500;
  background-color: #00529b;
  color: #fff;
  padding: 6px 16px;
  border-radius: 6px;
  display: inline-block;
  text-transform: uppercase;
}

.user_management > .create-role-wrapper {
  display: grid;
  place-items: end;
  position: relative;
  margin: 0;
}
