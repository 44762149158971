.action-btns.manage-disposition {
  .action-groups {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;

    .ctm-button {
      width: 100px;
    }
  }
}
