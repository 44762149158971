@import '../../../../variables.scss';

.Div_ProducedTxt{
    line-height: normal;
    min-height: 46px;
    display: flex;
    align-items: center;
}
.sb_txt_bold{
    font-weight: 600;
}
.addProduceItemBlock{

      .addProduceForm{
        padding: 20px;
    }
    
   .add_moreCtrl_row{
        padding:0 20px 15px;
   }

   .Add_ProducedForm{
        padding:20px 20px 0px ;
   }

    .bomAddProduceTotalWarp{
        padding: 20px 20px 20px 26%;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
        font-weight: $font-med;
        color:$colorBlack
    }

    .TotalNumWarp{
        position: relative;
        z-index: 1;

        .TotalLabel{
            position: absolute;
            left: -80px;
            top:50%;
            transform: translateY(-50%);
            color:$colorBlack
        }

        .sb_error_Color_txt{
            color: $colorRed;
        }
        .Sb_Percent100{
            color:#5DB96D;
        }
    }

    .sb_form_ActionBtn_row{
        padding-bottom: 16px;

        .act-icons {
            align-self: start;
      
            .MuiButtonBase-root {
              opacity: 0.3;
      
              .MuiSvgIcon-root {
                font-size: 30px;
              }
      
              &:hover {
                opacity: 1;
              }
            }
      
            .del_btn {
              color: $colorOrange;
            }
      
            .edit_btn {
              color: $siteBlueLight;
            }
          }

          &:first-child{
            .actBtnWrap{
                padding-top: 30px;;
            }
        }

    }
}

/*==============  Media Queries  ====================*/


@media (min-width:600px){
    .addProduceItemBlock{

        .sb_form_ActionBtn_row {

            label{
                min-height: 24px;
            }
            .custom_label,label{
                display: none;
            }
    
            &:first-child{
                .custom_label,label{
                    display: inherit;
                }
    
            }

        } 
        

    }
}

@media (min-width:600px) and (max-width:899px){
    .addProduceItemBlock .bomAddProduceTotalWarp{
        padding-left: 45.8333%;
    }
}

@media (min-width:900px) and (max-width:1199px){
    .addProduceItemBlock .bomAddProduceTotalWarp{
        padding-left: 33.3333%;
    }
}

@media (max-width:600px){
    .addProduceItemBlock .Add_ProducedForm .sb_form_ActionBtn_row .actBtnWrap{   padding-top: 30px;  }    
}