@import '../../../../variables.scss';

.Site_wrapper #main-container{
    .ppa_effDistDateGridWrap{
        border-radius: 10px;
        padding: 8px 16px 16px;
        background-color: $colorWhite;
        
        .MuiTable-root{
            min-width: 0;   
            th{
                text-align: center;              
            }
            tr:nth-child(2n){
                background-color: $colorWhite;
            }        
        }

        .MuiTablePagination-root{
            .MuiTablePagination-toolbar{
                padding-left: 0;
            }
            .MuiTablePagination-selectLabel,.MuiTablePagination-displayedRows {
                font-size: 13px;
            }
            .MuiInputBase-root{
                margin-right: 15px;
            }
        }

    }

} 

.ppa_effDistDateGridWrap {

    .pagination_edit .MuiSelect-standard{
        width: auto;
    }

    .gbl_roundIcon_grp .gbl_view_icon{
        background-color: $colorGreenDrak;        
    }

    .ppa_ActDateRow{
        td:first-child{
            position: relative;
            z-index: 1;
            font-weight: $font-med;
        }
        .ppa_brdLine{
            position: absolute;
            display: inline-block;
            width: 4px;
            height: 100%;
            background-color: #b2afff;
            left: 0;
            top:0;
        }

        .gbl_roundIcon_grp .gbl_view_icon{
            opacity: 1;     
        }
    }
   
}

