@import '../../../../variables.scss';


body .sb_modalStyle2.ppa_newHogBin_modal{
    .MuiDialogContent-root{
        min-height: 90px;
    }
} 

.ppa_newHogBin_modal{
    .ppa_binIncrementBox{

         /* Chrome, Safari, Edge, Opera */
         input[type=number]::-webkit-outer-spin-button,
         input[type=number]::-webkit-inner-spin-button {
         -webkit-appearance: none;
         margin: 0;
         }

         /* Firefox */
         input[type=number] {
             appearance: textfield;
             -moz-appearance: textfield;
         }

    }
}



/*==============  Media Queries  ====================*/


@media (min-width:600px){
    .ppa_newHogBin_modal{       

        // .sb_modal_form{
        //     padding-top: 35px;
        // }

        .MuiDialog-container{
            .MuiDialog-paper{
                max-width: 800px;
            }
        }
               

    }
}