@import '../../../../../../variables.scss';

.warehouse_table.manage_ccm_table_grid {
  margin: 0 !important;

  .switch_input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}
