@import '../../../../variables.scss';

.Site_wrapper #main-container{
    .ppa_AddNewHogFormWrapper{
        &.warehouse_table{
            margin-top: 0;
            margin-bottom: 0;

            .save_btn.Mui-disabled {
                color: $colorWhite;
                box-shadow: none;
              }
        }

        .ppa_frm_btnsWrap{
            padding-top: 0px;
         } 
    }
}

