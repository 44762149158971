@import '../../../variables.scss';
@import '../../../mixins.scss';

.sbSidebar {
  &.sidebarClose {
    visibility: hidden;
    transition: visibility 225ms cubic-bezier(0, 0, 0.2, 1);
  }

  &.sidebarOpen {
    visibility: visible;
  }

  > .MuiDrawer-paper {
    background-color: $siteBlueDark;
  }

  .sbLogoWrapper {
    padding: 5px 10px;
    background-color: $colorWhite;

    img {
      max-width: 70%;
      margin: auto;
      object-fit: contain;
    }
  }

  .MuiListItemText-primary {
    font-size: $font16;
  }

  .MuiCollapse-root {
    .MuiCollapse-wrapperInner {
      > .MuiList-root > .MuiListItem-root > .dwPrimTxt {
        position: relative;

        &::before {
          content: '';
          width: 7px;
          height: 7px;
          background: $colorWhite;
          display: none;
          @include border-radius(50%);
          margin-right: 8px;
          vertical-align: middle;
          position: absolute;
          top: 8px;
          left: -15px;
        }
      }

      .MuiCollapse-wrapperInner {
        > .MuiList-root > .MuiListItem-root > .dwPrimTxt {
          position: relative;

          &::before {
            content: '';
            width: 6px;
            height: 6px;
            background: $colorWhite;
            display: inline-block;
            @include border-radius(50%);
            margin-right: 8px;
            vertical-align: middle;
            position: absolute;
            top: 8px;
            left: -12px;
          }
        }

        .MuiListItemText-primary {
          font-size: 13px;
          color: $sbBlueLight2;
        }

        .MuiListItem-root {
          padding: 4px 16px 4px 24px;
        }
      }
    }

    .l2-icon {
      min-width: 47px;
      padding-left: 6px;
    }
  }

  .scrollbar-container {
    > .MuiListItem-root {
      .dwPrimIcon {
        padding: 4px;
        background: $siteBlueDark;
        @include border-radius(50%);
        min-width: 0;
        width: 36px;
        height: 36px;
        margin-right: 10px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .l1-selected {
    background-color: $sbBlueLight2;

    .dwPrimTxt .MuiTypography-root {
      color: $siteBlueDark;
      font-weight: $font-med;
    }

    .MuiSvgIcon-root {
      color: $siteBlueDark;
    }

    &:hover {
      background-color: $sbBlueLight2;
    }

    &:before {
      border-bottom: transparent;
      border-left-color: transparent;
      border-radius: 0 0 48px 0;
      border-top-color: transparent;
      bottom: 0;
      content: '';
      height: 30px;
      position: absolute;
      right: 0;
      top: -30px;
      z-index: 9;
      border-right: 21px solid $siteBlueDark;
    }

    &:after {
      border-bottom: transparent;
      border-left-color: transparent;
      border-radius: 0 48px 0 0;
      border-top-color: transparent;
      bottom: -30px;
      content: '';
      height: 30px;
      position: absolute;
      right: 0;
      top: auto;
      z-index: 0;
      border-right: 21px solid $siteBlueDark;
    }

    .shape1 {
      height: 30px;
      position: absolute;
      right: 0;
      top: -30px;
      width: 20px;
      background: $sbBlueLight2;
    }
    .shape2 {
      height: 30px;
      position: absolute;
      right: 0;
      top: auto;
      bottom: -30px;
      width: 20px;
      background: $sbBlueLight2;
    }

    &:first-child {
      .shape1,
      &:before {
        display: none;
      }
    }
  }

  .l3-selected {
    .dwPrimTxt .MuiTypography-root {
      color: $colorWhite !important;
      font-weight: $font-med;
      text-decoration: underline;
    }
  }

  .fa {
    color: $colorWhite;
    font-size: 18px;
  }

  .dwPrimIcon {
    .MuiSvgIcon-root {
      color: $colorWhite;
    }
  }

  .SB_sidebarDivider {
    border-color: #8bb8e1;
  }
}
