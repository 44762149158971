@import '../../../../variables.scss';

.list-object-wrapper {
  .create-action-btn {
    display: flex;
    justify-content: flex-end;

    .MuiButton-root:last-child {
      margin-left: 16px;
    }
  }

  .table_scroll {
    .MuiTableCell-head {
      background-color: transparent;
    }
  }
}

.create-action-btn.popup_footer {
  width: calc(100% - 40px);
  border-top: 1px solid #e2e2e2;
  text-align: center;
  padding: 20px 16px;
}
