@import '../../../../variables.scss';

.more_ccm_actions {
  color: $siteBlueDark;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
}
