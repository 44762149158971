@import '../../../../variables.scss';



.addConsumedItemBlock{

     .addConsumedForm{
        padding: 20px;
    }
    
   .add_moreCtrl_row{
        padding:0 20px 10px;
   }

   .addConsumedForm{
        padding:20px 20px 0px ;
   }

  

    .sb_form_ActionBtn_row{
        padding-bottom: 16px;

        .act-icons {
            align-self: start;
      
            .MuiButtonBase-root {
              opacity: 0.3;
      
              .MuiSvgIcon-root {
                font-size: 30px;
              }
      
              &:hover {
                opacity: 1;
              }
            }
      
            .del_btn {
              color: $colorOrange;
            }
      
            .edit_btn {
              color: $siteBlueLight;
            }            

          }

          &:first-child{
            .actBtnWrap{
                padding-top: 30px;;
            }
        }
    }

    
}

/*==============  Media Queries  ====================*/


@media (min-width:1200px){
    .addConsumedItemBlock{

        .sb_form_ActionBtn_row {

            label{
                min-height: 24px;
            }
            .custom_label,label{
                display: none;
            }
    
            &:first-child{
                .custom_label,label{
                    display: inherit;
                }
    
            }

        } 
        

    }
}


@media (max-width:1199px){
    .addConsumedItemBlock .addConsumedForm .sb_form_ActionBtn_row .actBtnWrap{   padding-top: 30px;  }    
}