@import '../../../../../variables.scss';



/*==============  Media Queries  ====================*/


@media (min-width:1200px){

     .ppa_AddItemCode_modal{

        .MuiDialog-paper{
            max-width: 1300px;
        }
       

        .sb_modal_form {
            padding-top: 35px;
        }

        .modal_form_ActionBtn_row {

            label{
                min-height: 24px;
            }
            .custom_label,label{
                display: none;
            }
    
            // &:first-child{
            //     .custom_label,label{
            //         display: inherit;
            //     }
    
            // }

        } 
        

    }
}