@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,700&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import './variables.scss';
@import './mixins.scss';
// start _general_var

body {
  background-color: $sbBlueLight2;
}

.boxShadow {
  -webkit-box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.24);
}

/*==== Custom alter inline start ========*/

.SB_custom_notification {
  border-radius: 8px !important;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 0;
  align-items: center;

  &.size_sm {
    padding: 6px 20px;
  }

  &.sb_cus_error {
    background-color: #fdeded;

    .close_btn,
    .MuiButtonBase-root {
      color: $colorRed;
    }

    .cus_note_icon,
    .MuiAlert-icon {
      color: $colorRed;
    }
  }

  .MuiAlert-message {
    color: $bodycolor;
    font-size: $font16 - 2;
    font-family: $body1;
  }
}
/*==== Custom alter inline ends ========*/

button.MuiButton-root {
  border-radius: 6px;
  box-shadow: none;
}

.pd_right_5 {
  padding-right: 5px;
}

span.star {
  color: #f00;
}

.flex-row-center-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.MuiInputLabel-asterisk {
  color: #f00;
}

.MuiDialog-root.sb_modal,
.sb_modalStyle2 {
  .MuiDialog-paper {
    border-radius: 10px;
    border: none;
    outline: 10px solid rgba(0, 0, 0, 0.3);
  }

  .popup_info {
    .no_btn:hover {
      background-color: rgba(0, 82, 155, 0.04);
      color: $siteBlueDark;
    }
  }

  .ReadOnlyRow {
    /* Chrome, Safari, Edge, Opera */
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  h2.MuiDialogTitle-root {
    font-weight: $font-med;
  }
}

button.sb_btn {
  min-width: 110px;
}

.sb_link_btn {
  &.Mui-disabled {
    opacity: 0.7;
  }
}

button.ghost-btn {
  outline: 1px solid;
  background-color: $colorWhite;
}

.sb_popup_form {
  label {
    font-weight: 500;
  }
}

.fixModalHeight460 {
  .MuiDialogContent-root {
    max-height: 460px;
  }
}

.sb_modal_form {

    .Mui-disabled.MuiFormControlLabel-root{
      background-color: transparent;

      >.MuiFormControlLabel-label.Mui-disabled{
        background-color: transparent;
      }
  }


  .MuiFormControl-root .MuiFormHelperText-root {
    margin: 0;
  }

  label {
    font-weight: 500;
    font-family: $body1;
  }

  .MuiInputBase-input {
    padding: 11px 10px;
  }

  .modal_form_ActionBtn_row {
    padding-bottom: 15px;

    .act-icons {
      align-self: end;

      &.hasErrorText {
        padding-bottom: 20px;
      }

      .MuiButtonBase-root {
        opacity: 0.3;

        .MuiSvgIcon-root {
          font-size: 30px;
        }

        &:hover {
          opacity: 1;
        }
      }

      .del_btn {
        color: $colorOrange;
      }

      .edit_btn {
        color: $siteBlueLight;
      }
    }
  }

  .align-grid-items {
    .raw-input > .MuiGrid-item {
      display: flex;
      align-items: center;
    }

    label {
      display: none !important;
    }

    .MuiGrid-item {
      padding-top: 16px;
    }
    .editable-input {
      .edit_btn,
      .del_btn {
        left: 10px;
      }
    }
  }
}

.MuiFormControl-root {
  .MuiInputBase-root:not(.Mui-disabled) {
    background-color: $colorWhite;
  }
}

.sb_AutoSearchDD {
  .MuiAutocomplete-root {
    .Mui-disabled {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      -webkit-text-fill-color: currentColor;
      opacity: 0.9;
      background-color: $altgrayBg;
      background: none;
      svg {
        display: none;
      }
    }

    &:not(.Mui-focused) {
      .MuiAutocomplete-input {
        width: 0;
        min-width: unset;
      }
    }
  }

  .MuiFormHelperText-root {
    color: #cc2936 !important;
    font-size: 12px !important;
    margin-block: 5px !important;
  }
}

.Mui-disabled {
  background-color: #f4f5ff;
}

.table_scroll {
  tr {
    &:nth-child(1) {
      th {
        &:last-child {
          .edit_btn_header {
            background-color: $siteBlueDark;
            &.icon_disable {
              background-color: $disablebtn;
            }
          }

          .clone_btn_header {
            background-color: $colorGreen;
            &.icon_disable {
              background-color: $disablebtn;
            }
          }
        }
      }
    }
  }
}

// end_general_ver

//.pageTitleBlock {
.Site_wrapper {
  display: flex;
  height: 100vh;

  h1 {
    color: $colorBlack;
  }

  h2 {
    color: $colorBlack;
  }

  h3 {
    color: $colorBlack;
  }

  h4 {
    color: $colorBlack;
  }

  h5 {
    color: $colorBlack;
  }

  h6 {
    color: $colorBlack;
  }

  a {
    color: $siteBlueDark;
    text-decoration: none;
  }

  .sidebar {
    width: 290px;
    background-color: $siteBlueDark;
    display: none;
  }

  .star {
    color: #f00;
  }

  .table_below_button {
    text-align: right;
    padding-top: 25px;

    .post_btn {
      margin-right: 18px;
    }

    .gold_btn {
      background-color: $colorGold;
    }
  }

  .btn_disable {
    background-color: $disablebtn;
    pointer-events: none;
    box-shadow: none;
  }

  .btn_disable:hover {
    background: $disablebtn;
  }

  button.MuiButtonBase-root.MuiButton-contained {
    border-radius: 6px;
  }

  .MuiPaper-root.MuiPaper-rounded {
    border-radius: 10px;
  }

  .main_container_wrap {
    @media only screen and (min-width: 1200px) {
      min-width: calc(100% - 290px);
    }
  }

  #main-container {
    font-family: $body1;
    width: 100%;
    padding: 0px 15px;
    padding-bottom: 30px;

    .Page_title {
      h2 {
        font-size: 30px;
        margin-top: 0px;
        margin-bottom: 10px;
      }
    }

    .sb_box_shadow {
      box-shadow: 0px 4px 10px 2px rgb(218 222 232);
      border-radius: 10px;
      .MuiPaper-root {
        box-shadow: none;
      }
    }

    .siteHeader {
      background-color: #fff;
    }

    .sb_panel {
      > div {
        padding: 0;
      }

      .table-action {
        display: flex;
        margin: -0.25rem 1rem 0;
        gap: 0.5rem;

        button {
          height: 2.5rem;
        }
      }

      .MuiAccordion-root {
        .MuiSvgIcon-root {
          border: 1px solid $borderColor;
        }

        .MuiAccordion-region {
          .MuiSvgIcon-root {
            border: none;
          }
        }
      }
    }

    .siteHeader {
      background-color: $colorWhite;
    }

    .MuiAccordionSummary-content {
      margin: 15px 0 0;
    }

    .warehouse_table {
      margin-bottom: 25px;
      background-color: $colorWhite;
      margin-top: 30px;

      .panel_title {
        padding: 16px;
        border-bottom: 1px solid $borderColor;
        display: flex;

        &.gbl_penelTitle_withlink{
          padding-top: 12px;
          padding-bottom: 12px;
        }

        h3 {
          margin: 0px;
        }
      }

      .roles_title {
        h3 {
          margin: 0px;
          padding: 16px 16px 0;
          font-weight: 600;
        }
      }

      .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
      }

      .body_content {
        padding: 16px;
        color: $bodycolor;

        &.p-0 {
          padding: 0;
        }

        .table_export_btn {
          text-align: right;
          margin-bottom: 15px;

          .common_btn {
            &.pdf_btn {
              &:hover {
                background-color: $siteBlueLight;
              }
            }

            &.excel_btn {
              &:hover {
                background-color: $colorGreen;
              }
            }
          }
        }

        .MuiPaper-root {
          box-shadow: none;
        }
      }
    }

    .gbl_table_structure_Only{
        .warehouse_table {
          border-radius: 0;
          box-shadow: none;
          margin: 0;
        }

        .panel_title{
          display: none;
        }

        .body_content{
          padding-left: 0;
          padding-right:0;
          padding-bottom: 0;

            >.MuiPaper-root{
              border-radius: 0;
            }
        }

    }

    .warehouse_table {
      tr {
        &:nth-child(even) {
          background-color: $tablechildevencolor;
        }

        th {
          font-size: 15px;
          font-weight: 600;
          border: 1px solid $borderColor;
          text-transform: $sbFontAllcaps;
          background: none;
        }

        td {
          border: 1px solid $borderColor;
          padding: 10px;
          word-wrap: break-word;
          max-width: 350px;
        }
      }
    }
  }
}

.create-action-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  .MuiButton-root {
    width: 120px;
    padding: 8px;
  }

  .MuiButton-root:last-child {
    margin-left: 16px;
  }
}

.sb_form .MuiChip-filledDefault {
  background-color: #eaedf7;
  border-radius: 5px;
  min-width: 70px;
  height: 26px;
  justify-content: left;
}

.body_content .pagination_edit .MuiSelect-standard {
  border: 1px solid #ccc;
  width: 40px;
  text-align-last: left;
  border-radius: 5px;
}

.MuiAutocomplete-popupIndicator > svg {
  opacity: 0;
}

.sb_form .MuiAutocomplete-popupIndicator {
  background: url('assets/images/dropdown_icon.png') no-repeat, scroll;
  width: 40px;
  height: 20px;
  background-position: top 5px right 10px;
  @include transform-rotate(0deg);
  &:hover {
    background-color: transparent;
  }
}

.MuiChip-filledDefault {
  background: url('assets/images/dropdown_cancel.png') no-repeat, scroll;
  background-position: center right 10px;
  svg {
    opacity: 0;
  }
}

/*=========Form error css start */
.sb_form {
  .MuiInput-root {
    &.MuiInput-colorDanger {
      border-color: $colorRed;
      outline: $colorRed;
    }
  }
}

/*=========Form error css ends */

div.MuiCalendarPicker-root {
  .MuiPickersDay-today {
    background-color: $colorGold;
    border: $colorGold;
    color: $colorWhite;
  }

  .MuiPickersDay-root {
    .Mui-selected {
      background-color: $siteBlueLight;
      border: $siteBlueLight;
      color: $colorWhite;
    }
  }
}

/* changes_QA */

.search-filter-dropdown {
  .MuiAutocomplete-endAdornment {
    top: auto;
    bottom: 15px;
  }
  .MuiButtonBase-root {
    right: 0px;
  }
}

ul {
  &.tb_res {
    &.tb_ext_chg {
      & > li > div:nth-child(2) {
        flex: 1 !important;
        max-width: 100% !important;
      }
      .tb_res_val {
        span {
          text-align: left;
          word-wrap: break-word;
        }
      }
    }
    .tb_res_head {
      width: 280px;
    }
    .tb_group {
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }
  }
}

.list-style2 {
  ul.tb_res {
    .tb_res_head {
      font-weight: $font-med;
      width: 180px;
    }
    .tb_res_val span {
      font-size: $font16;
      text-align: left;
    }
  }
}

.sb_AutoSearchDD {
  .MuiAutocomplete-root {
    position: relative;
    min-height: 46px;
    z-index: 1;

    &.Mui-focused {
      z-index: 2;
    }

    .MuiInputBase-root {
      position: absolute;
      overflow-y: auto;
      background-color: #fff;
      z-index: 10;
      padding-right: 65px;

      &.Mui-disabled {
        background-color: #f4f5ff;
      }

      &.MuiOutlinedInput-root {
        padding: 4px 9px;
      }
    }

    .MuiAutocomplete-tag {
      max-width: calc(100% - 75px);

      .MuiChip-label {
        min-width: 60px;
      }
    }
    .MuiAutocomplete-endAdornment {
      top: 7px;
      transform: none;
    }
  }
}

.Site_wrapper .sb-form-panel-accr {
  overflow: visible;
}

.sb_DatePicker {
  .MuiInputBase-root {
    padding: 3px 20px 6px 8px;
  }
}

.sb_DatePicker {
  .MuiInputBase-root input{
    padding: 7.5px 4px 7.5px 6px;
  }
}

.SbDateRangePicker{

  .MuiInputBase-root {
      background: url('assets/images/date-range-calender.svg') no-repeat scroll  96% 12px;
      background-size: 22px auto;
      padding: 4px 20px 4px 8px;
    }

   .MuiInputBase-input {
      padding: 7.5px 4px 7.5px 6px;
   }

   .MuiMultiInputDateRangeField-separator{
      display: none;
   }
}

/* end */

/* pageTitleBlock start */

.pageTitleBlock {
  padding: 30px 0;
}

/* pageTitleBlock ends */

/* Breadcrumb */

.breadcrumb_blk {
  a {
    color: #a6a4a5;
  }
  p {
    color: $siteBlueDark;
    text-decoration: none;
  }
}

/* Breadcrumb_end */

// start_circle_btn
.common_btn_lg {
  @extend %common_btn_lg;
}
.common_btn {
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 50px;
  font-size: 16px;
  display: inline-block;
  line-height: 34px;

  & i::before {
    color: $colorWhite;
    font-size: 16px;
  }

  &.view_btn {
    background-color: $colorGreen;
    cursor: pointer;
    text-decoration: none;
  }

  &.edit_btn {
    background-color: $siteBlueLight;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  &.clone_btn {
    background-color: $colorGreen;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  &.edit_tr_btn {
    background-color: $siteBlueLight;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: $colorWhite;

    > span {
      display: block;
    }

    .MuiSvgIcon-root {
      font-size: 24px;
      line-height: 1;
      margin-top: 6px;
      position: relative;
    }

    &.icon_arrow_up {
      opacity: 1;
      .MuiSvgIcon-root {
        transform: rotate(180deg);
      }
    }
  }

  &.delete_btn {
    background-color: $colorOrange;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  &.pdf_btn {
    background-color: $siteBlueLight;
    margin-right: 15px;
  }

  &.excel_btn {
    background-color: $colorGreen;
  }

  &.icon_disable {
    background-color: $disablebtn;
    pointer-events: none;
  }

  &.btn_disable {
    opacity: 0.5;
  }
}



// end_circle_btn

// new set of table action icon start


.gbl_roundIcon_grp{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  .gbl_round_icon {
    cursor: pointer;
     .MuiSvgIcon-root{
        color: $colorWhite;        
      }
      margin: 0 4px;
  }
  

      .gbl_view_icon {
        background-color: $colorGreen;       
      }

      .gbl_edit_icon{
        background-color: $siteBlueLight;
      }

      .gbl_clone_icon {
        background-color: $colorGreen;
      }

      .gbl_edit_tr_icon {
        background-color: $siteBlueLight;  
        color: $colorWhite;
       
        &.gbl_icon_arrow_up {
          opacity: 1;
          .MuiSvgIcon-root {
            transform: rotate(180deg);
          }
        }
      }

      .gbl_del_btn {
        background-color: $colorOrange;        
      }

}

.table_scroll{
  td {
    .gbl_round_icon {
      opacity: 0.3;
  
      &:hover,
      &.act {
        opacity: 1;
      }
  
      &.gbl_highlight_icon {
        opacity: 1;
      }
  
      &.gbl_icon_disabled {
        opacity: 0.3;
        pointer-events: none;
        cursor: default;
        background-color: $disablebtn;
      }
      &.Mui-disabled{
        background-color: $disablebtn;
        opacity: 1;
      }

    }
  }
}


// new set of table action icon ends

// start_large_btn

.MuiPaper-root .common_btn_lg {
  @extend %common_btn_lg;

  /* end_large_btn */

  &.gold_btn {
    background-color: $colorGold;
  }

  &.orange_btn {
    background-color: $colorOrange;
  }
}

%common_btn_lg {
  width: 110px;
  height: 37px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none;
}
.panel_title h3 {
  font-weight: 600;
  text-transform: $sbFontCapitalize;
  font-size: 20px;
  color: $colorBlack;
  margin-top: 2px;
  margin-bottom: 15px;

  &.sbFontAllcaps {
    text-transform: $sbFontCapitalize;
    font-size: 20px;
    color: #000;
    margin-top: 2px;
    margin-bottom: 15px;
    &.sbFontAllcaps {
      h3 {
        text-transform: $sbFontAllcaps;
      }
    }
  }
}

.sb_form label {
  font-size: $font16;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  color: $colorBlack;
  font-weight: 500;

  .TooltipIcon.MuiSvgIcon-root {
    // color: #ebe1b5;
    margin-left: 0.25rem;
    cursor: pointer;
  }

    .rmCtrlIcon{
      background-color: #FFF5F1 ;
      border-radius: 50%;
      box-shadow: 0 2px 4px -1px #0000001a;
      position: absolute;
      right: 0;
      top:0;
      z-index: 1;
      display: none;
      width: 22px;
      height: 20px;
      text-align: center;
      line-height: 22px;
      border:1px solid $colorOrange;
      cursor: pointer;       

      .rmIcon{
          color:$colorOrange;
          font-size: 16px;
          display: inline-blockl;
          margin: 0;
          
      }
  }

  &.hasIcon {
    position: relative;
    z-index: 1;

    .MuiButtonBase-root {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      z-index: 1;
      padding: 0;
    }

    .MuiSvgIcon-root {
      color: $siteBlueDark;
    }

    .rmIcon{
      color:$colorOrange;
    }  

      &:hover{
        .rmCtrlIcon{
          display: block;
        }
      }

  }
  
}

.custom-table.sb_form label {
  display: none;
}

.custom-element-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.search_filter_button {
  text-align: right;
  padding-top: 25px;

  .search_btn,
  .action_btn {
    margin-right: 18px;
  }
}

.MuiPaper-root {
  .MuiTypography-root {
    flex: 1;
  }
  .page_title_bor_bot {
    border-bottom: 1px solid $borderColor;
  }
  .MuiAccordionDetails-root {
    padding-top: 20px;
  }
}

.table_scroll {
  overflow-x: auto;
  table {
    max-width: 100%;
    /*min-width: 1050px;*/
  }

  .icon_grp {
    display: flex;
    justify-content: center;
    svg {
      width: auto;
      margin-right: 0;
    }
    a {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .MuiAccordionDetails-root {
    padding-top: 20px;
  }

  th {
    .common_btn {
      &:only-of-type {
        margin: 0;

        .MuiSvgIcon-root {
          margin-right: 0;
        }
      }

      .MuiSvgIcon-root {
        width: 22px;
      }
    }
  }
  td {
    .common_btn {
      opacity: 0.3;

      &:hover,
      &.act {
        opacity: 1;
      }

      &.highlight {
        opacity: 1;
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
        cursor: default;
      }

      &:only-of-type {
        margin: 0;
      }
    }
  }
}

.table_export_btn .expLink {
  text-decoration: none;
}

.table_export_btn .expLink .fa {
  display: inline-flex;
}

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: #ccc;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: #ccc;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

body {
  .blue_btn {
    padding: 6px 16px;
    color: $colorWhite;
    background-color: $siteBlueDark;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin-left: auto;
    display: flex;
    &:hover {
      background-color: $siteBlueDark;
    }
  }
}

/*  Modal Stiky label css start */

.modal_form_stikyLable {
  position: absolute;
  background-color: $colorWhite;
  top: 57px;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 50px;
  box-sizing: border-box;
  padding: 16px 20px 10px;

  &.modalhasScroll {
    width: calc(100% - 17px);
  }

  @media (hover: none) {
    &.modalhasScroll {
      width: calc(100% - 0px);
    }
  }

  // @supports (scroll-behavior:smooth) {
  //   &.modalhasScroll{
  //     width: calc(100% - 0px);
  //   }
  // }

  @media screen and (-webkit-max-device-pixel-ratio: 1) and (min-color-index: 0) {
    &.modalhasScroll {
      width: calc(100% - 0px);
    }
  }

  /* Detect Mac Safari 6.1 or and Chrome references */
  @media screen and (-webkit-max-device-pixel-ratio: 1) and (min-color-index: 0) {
    @supports (not (-moz-appearance: none)) {
      &.modalhasScroll {
        width: calc(100% - 0px);
      }
    }
  }

  /* Detect Mac Firefox 25 or newer */
  @supports (-moz-osx-font-smoothing: auto) {
    &.modalhasScroll {
      width: calc(100% - 0px);
    }
  }

  .MuiGrid-container {
    margin-top: 0;
    .MuiGrid-item {
      padding-top: 0;
    }
  }
}

/*  Modal Stiky label css ends */

/* Tabs Css start  =====*/

.sb_tabWarpper {
  margin-bottom: 30px;
  .sbTabs {
    .MuiButtonBase-root {
      color: $colorBlack;
      font-weight: $font-med;
      white-space: nowrap;
      &:hover{
        color: $siteBlueDark;
      }
    }

    .Mui-selected {
      color: $siteBlueDark;
    }
  }
}

@media (max-width: 767px) {
  .sb_tabWarpper {
    .MuiTabs-flexContainer {
      overflow-x: auto;
    }
  }
}

@media (min-width: 1366px) {
  .sb_tabWarpper {
    .sbTabs {
      .MuiButtonBase-root {
        font-size: 20px;
      }
    }
  }
}

/* Tabs css ends ======*/


/*  css for tabs title with link/button in righ side start*/

.gbl_tabsTitleRow{
  display: flex;
  justify-content: space-between;
  padding: 0;

  .gbl_tabsRightLinkWrapper{
      padding: 6px 14px  0;

      > .MuiButton-text:hover{
          background-color: transparent;            
      }
  }

  .gbl_pipe_line{color: $borderColor;}
}

/*  css for tabs title with link/button in righ side ends*/

/*  css for panel title with link/button start*/

.gbl_penelTitle_withlink{
  .gbl_title_content_Box{
    align-items: center;
    display: flex;
    flex: 1 1;
    justify-content: space-between;

      .sb_btn{
        font-size: $font16;

        &.MuiButton-text:hover{
          background-color: transparent;
        }
      }
  }
}

/*  css for panel title with link/button start*/

/*==========Glogel fix for date range  picker start========*/

.MuiPickersPopper-root{
  .MuiPaper-root {

          .MuiTypography-root {
            flex: none;
          }
    }
}

.MuiCalendarPicker-root{
  .MuiTypography-caption{
    flex: none;
  }
}

/*==========Glogel fix for date range  picker ends========*/


/* media_query */

.Site_wrapper {
  #main-container {
    @media (min-width: 600px) {
      padding: 0 30px;
      padding-bottom: 30px;
    }
  }
}


/*==========Glogel fix for Input type number  picker start========*/

body{
  /* Chrome, Safari, Edge, Opera */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 0;
  }

  /* Firefox */
  input[type=number] {
      appearance: textfield;
      -moz-appearance: textfield;
  }

  .Mui-disabled{
    /* Chrome, Safari, Edge, Opera */
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    opacity: 0;
    }

    /* Firefox */
    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
}


}



/*==========Glogel fix for Input type number  picker ends========*/