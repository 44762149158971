@import '../../../variables.scss';


/*========= Globle css =========*/

.sb_tabWarpper{
    margin-bottom: 30px;
    .sbTabs{        
        .MuiButtonBase-root{             
          &:hover{
            color: $siteBlueDark;
          }
        }
        
    }
  }


/*========= Component css ========*/

.Site_wrapper #main-container{
    .ItemConstraintsPage .warehouse_table {
        
        tbody tr{
            &:nth-child(even) {
                background-color: $colorWhite;
            }
            &:nth-child(odd) {
                background-color: $tablechildevencolor;
            }
        }
    }
}




.ItemConstraintsPage{
    .pagination_edit{
        .MuiSelect-select{
            border: 1px solid #ccc;
            width: 40px;          
            border-radius: 5px;
            text-align-last: left;
        }
    }

    .warehouse_table{

        .edit_btn{
            background-color: $siteBlueLight;
        }
    
        .del_btn {
            background-color: $colorOrange;
         }
    
        .view_btn{
            background-color: $colorGreen;      
        }
    
        table{
            .MuiIconButton-root{
                color: $colorWhite;
                opacity: 0.3;
                &:hover,&.act{
                    opacity: 1;
                }
                .MuiSvgIcon-root{
                    width: 1em;
                     height: 1em;
                     margin: 0;
                }
            }
        }
       

    }    

    .sb_tabWarpper .MuiTabs-flexContainer{
        overflow: visible;
    }

}
  

.ppa_MgeConstraintName_modal,
.ppa_MgeConstraintType_modal{

    .sb_modal_form {
        .modal_form_ActionBtn_row {
            padding-bottom: 15px;
        
            .act-icons {           
        
                .del_btn.Mui-disabled {
                    color: $colorBlack ;
                    opacity: 0.1;
                }
        
            
            }
        }
    }

}



#filter-customized-menu.constrainTypeFilterDD .MuiPopover-paper{
    min-width: 200px;
}


.Site_wrapper #main-container{
    .ItemConstraintsPage{

        margin-bottom: 30px;

        .warehouse_table{
            margin: 0;
        }

        .typeSetpTabContent{
            &.sb_form {
                label{display: none;}
            }
            
            .tabs_topPanel_Btns{
                text-align: center;
                padding: 20px 0  0;

                .sb_btn{
                    margin-bottom: 1px;
                }
            }
        
        }

         button.sb_btn.btn_disable {
            box-shadow: none;
            color: #fff;
          }
    }

    .typeSetpTabContent{
        .sb_DDTitle_btn.MuiButtonBase-root{
            position: static;
        }
        
    }
}

.typeSetpTabContent{

            tr td,tr th{
            &.typeSetupActTD{
                width: 120px;
                min-width: 120px;
            }
            
        }
    
    .sb_AutoSearchDD{
        min-width: 200px;
    }

    .mgmtConstraintBTNs{
        .gost-btn{margin: 1px;}
        .MgmtConstraintType_btn{margin-right: 1px;margin-top: 1px;}
    }
    .edit_btn{
        background-color: $siteBlueLight;
        color: $colorWhite;
        opacity: 0.3;
        &:hover,&.act{
            opacity: 1;
        }
        .MuiSvgIcon-root{
            width: 1em;
             height: 1em;
             margin: 0;
        }
        
    }
    .save_btn{
        background-color: $colorGreen;
        color: $colorWhite;
        opacity: 0.3;
        &:hover,&.act{
            opacity: 1;
        }
        .MuiSvgIcon-root{
            width: 1em;
             height: 1em;
             margin: 0;
        }
    }
}


.ppa_consnt_typeTabs_btn_wrap{
    display: flex;
    justify-content: center;

    .ppa_type_setup_alert{
        margin-left: 10px;
    }
}

@media (max-width:767px) {

    .ppa_consnt_typeTabs_btn_wrap {
     flex-direction: column;
        .ppa_type_setup_alert{
            margin-left: 0;
            margin-top: 10px;
        }
     
    }
    
}

