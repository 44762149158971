.dataGrid {
  :global {
    .MuiDataGrid-columnHeaderTitle {
      font-size: 16px;
      font-weight: bold;
    }

    .MuiDataGrid-cell{
      font-size: 16px;
    }

    .MuiDataGrid-row:nth-of-type(odd) {
      background-color: #EDECFFBF
    }
  }
}