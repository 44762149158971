@import '../../../variables.scss';
@import '../../../mixins.scss';

.warehouse_table.dialog_table_grid {
  font-family: $body1;
  width: 100%;
  margin: 0 !important;
  background-color: $colorWhite;
  margin-bottom: 25px;
  margin-top: 30px;

  a {
    text-decoration: none !important;
  }

  .btn_disable {
    opacity: 0.5;
  }

  .btn_disable {
    background-color: $disablebtn;
    pointer-events: none;
    box-shadow: none;
  }

  .btn_disable:hover {
    background: $disablebtn;
  }

  .switch_input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .panel_title {
    padding: 16px;
    border-bottom: 1px solid $borderColor;
    display: flex;

    &.gbl_penelTitle_withlink {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    h3 {
      margin: 0px;
    }
  }

  .roles_title {
    h3 {
      margin: 0px;
      padding: 16px 16px 0;
      font-weight: 600;
    }
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  .body_content {
    color: $bodycolor;

    &.p-0 {
      padding: 0;
    }

    .table_export_btn {
      text-align: right;
      margin-bottom: 15px;

      .common_btn {
        &.pdf_btn {
          &:hover {
            background-color: $siteBlueLight;
          }
        }

        &.excel_btn {
          &:hover {
            background-color: $colorGreen;
          }
        }
      }
    }

    .MuiPaper-root {
      box-shadow: none;
    }
  }

  tr {
    &:nth-child(even) {
      background-color: $tablechildevencolor;
    }

    th {
      font-size: 15px;
      font-weight: 600;
      border: 1px solid $borderColor;
      text-transform: $sbFontAllcaps;
      background: none;
    }

    td {
      border: 1px solid $borderColor;
      padding: 10px;
      word-wrap: break-word;
      max-width: 350px;
    }
  }
}
