@import '../../../../variables.scss';


/*======== Module  css======*/


.Sb_BOMWrap_box{

    border: 1px solid $borderColor;   
    margin-bottom: 16px;

    .sb_AutoSearchDD{
        label{
            color: $siteBlueDark;
        }
    }

    .DD_blackLabel{
        .sb_AutoSearchDD{
            label{
                color: $colorBlack;
            }
        }
    }
}