@import '../../../../../variables.scss';

.PPA_calenderDrawer{

    .ProdlineCtrlBox{
        .MuiFormControlLabel-root{
            &.Mui-disabled{
                background-color: transparent;

                .Mui-disabled{
                    background-color: transparent;
                }

            }
        }
    }
   
    >.MuiDrawer-paper{
        padding-bottom: 50px;
        box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.1);
        min-height: 650px;
    }

    .MuiButton-text{
        &:hover{
            background-color: transparent;
        }
    }

    .CalenderHeader{
        background:$siteBlueDark;
        padding-left: 20px;

        h3{
            color: $colorWhite;
            font-size: 20px;
            display: flex;
            align-items: center;
            align-content: center;

            .MuiSvgIcon-root{
                margin-right: 10px;
                font-size: 28px;
            }
        }

        .MuiButtonBase-root{
            color: $colorWhite;            
        }
    }

    .DateSelectionInfo{
        padding: 10px 20px;
        h5{            
            color: #A6A5A4;
            font-size: 17px;
            font-weight: $font-med;
            margin: 0 0 10px;

            strong{
                color: $bodycolor;
            }
        }

        .dateStatusList{
            font-size: 16px;
            position: relative;
            z-index: 1;
            padding-left: 14px;
            margin-right: 10px;

            &:before{
                content: '';
                display: inline-block;
                width: 7px;
                height: 7px;
                position: absolute;
                left: 0;
                top:40%;
                background-color: #EAECF8;
                border-radius: 50%;
            }

            &.selectDate{
                color:$siteBlueDark;
                &:before{
                    background-color:$siteBlueDark;
                }
            }
        }
        
    }

    .ProdlineCtrlBox,
    .ApplyBtnRow{
        padding: 10px 20px;
    }

    .calenderBox{
        padding: 20px 20px 2px 20px;  

        .MuiPickersCalendarHeader-root{
            padding-left: 14px;
        }
        
        .MuiDayCalendar-root{            

            .MuiDayCalendar-weekContainer{
                justify-content: space-evenly;
            }
        }
    }

    .apply_calender_btn{
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%); 
        width: calc(100% - 40px);  
        
        &.Mui-disabled{
            color:$colorWhite;           
        }
    }  

}