@import '../../../../variables.scss';

.Sb_P2CWrap_box{
    .Accr_Content_box{


        .MuiTable-root th{
            &:first-child{
                text-align: left;
                padding-left: 15px;
            }
        }

        tbody{
            td{
                &:first-child{
                    text-align: left;
                }
            }
        }

        .sb_AutoSearchDD {
            max-width: 300px;
            label{
                display: none;
            }
            
        }

        .rm_codeInpTxt{
            max-width: 300px;
            .MuiInputBase-root{
                background-color: $colorWhite;
            }
           
        }

        .MuiTextField-root.sb_p2c_tblinput{
            max-width: 80%;

            .MuiInputBase-input{
                padding: 11.5px 14px;
                text-align: center;
            }
            
        }

        .del_btn{
            opacity: 0.3;
            background-color: $colorOrange;
            color: $colorWhite;
            padding: 5px;

            &:hover,.act{
                opacity: 1;
            }

            .MuiSvgIcon-root{
               font-size: 18px;
            }
        }

        .edit_btn{
            background-color: $siteBlueLight;
            color: $colorWhite;
            opacity: 0.3;
            &:hover,&.act{
                opacity: 1;
            }
            .MuiSvgIcon-root{
                width: 1em;
                 height: 1em;
                 margin: 0;
            }
            
        }
        
    }
}

.Site_wrapper #main-container {
    .Sb_P2CWrap_box{
        .warehouse_table{
            tbody{

                tr{
                    &:nth-child(odd) {
                        background-color: $altgrayBg;
                      }
                    &:nth-child(even) {
                        background-color: $colorWhite;
                      }
                }

                td{
                    &:first-child{
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}


