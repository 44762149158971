@import '../../../../variables.scss';

.Site_wrapper{
    #main-container{
        .UnassignedConstraintsGrid{

            .warehouse_table{
                margin: 0;
                box-shadow:none;
                border-radius: 0;
        
                .body_content{
                    padding: 0;
                }
        
                .MuiPaper-root.MuiPaper-rounded{
                    border-radius: 0;
                }
            }
        
            .panel_title{
                display: none;
            }
        }

    }
}




.NotificationRow{
    text-align: center;
    .SB_custom_notification{
        display: inline-flex;
    }
}