.emptyData_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  text-align: center;

  span {
    font-weight: 300;
    color: gray;
  }
}
