
html, body , #root{
    height: 100%;
   }
   #root {
       > div {
           height: 100%;
       }
   }
   
   .iam_login
   {
       width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
   

       
       img
       {
           max-width:280px;
           margin:0 auto;
       }
    p
     {
       text-align: center;
       font: normal normal normal 20px/31px Open Sans;
       color : #000000
     }
     a
     {
       color :  #00529B;
     }
   }
   .h-10 {
       height: 100%;
   }