@import '../../../../../variables.scss';



/*==============  Media Queries  ====================*/


@media (min-width:600px){
    .ppa_manageSpecialty_modal{         

        .MuiDialog-container{
            .MuiDialog-paper{
                max-width: 550px;
            }
        }  

    }
}