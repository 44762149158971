@import '../../../../../../variables.scss';

.dialog-table {
  & .table_scroll {
    overflow: unset;
  }

  &.warehouse_table {
    margin-block: 1rem;
    background-color: $colorWhite;

    .panel_title {
      padding: 16px;
      border-bottom: 1px solid $borderColor;
      display: flex;

      &.gbl_penelTitle_withlink {
        padding-top: 12px;
        padding-bottom: 12px;
      }

      h3 {
        margin: 0px;
      }
    }

    .roles_title {
      h3 {
        margin: 0px;
        padding: 16px 16px 0;
        font-weight: 600;
      }
    }

    .MuiTabs-scrollButtons.Mui-disabled {
      opacity: 0.3;
    }

    .body_content {
      color: $bodycolor;

      &.p-0 {
        padding: 0;
      }

      .table_export_btn {
        text-align: right;
        margin-bottom: 15px;

        .common_btn {
          &.pdf_btn {
            &:hover {
              background-color: $siteBlueLight;
            }
          }

          &.excel_btn {
            &:hover {
              background-color: $colorGreen;
            }
          }
        }
      }

      .MuiPaper-root {
        box-shadow: none;
      }
    }

    .sb_AutoSearchDD {
      .MuiAutocomplete-root {
        .MuiAutocomplete-tag {
          max-width: calc(100% - 40px);
          .MuiChip-label {
            min-width: 70px;
          }
        }
      }
    }
  }

  &.warehouse_table {
    tr {
      &:nth-child(even) {
        background-color: $tablechildevencolor;
      }

      th {
        font-size: 15px;
        font-weight: 600;
        border: 1px solid $borderColor;
        text-transform: $sbFontAllcaps;
        background: white;
        position: sticky;
        top: 0;
      }

      td {
        border: 1px solid $borderColor;
        padding: 10px;
        word-wrap: break-word;
        max-width: 350px;
      }
    }
  }
}
