@import '../../../variables.scss';
@import '../../../mixins.scss';

.Site_wrapper {
  .sbAppBar {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 10%);

    .MuiBadge-badge {
      font-size: 11px;
      width: auto;
      height: auto;
      padding: 4px;
    }

    .sbAppLogo {
      cursor: pointer;
    }

    .ProfileIconBox {
      box-shadow: 2px 2px 10px -1px rgb(0 0 0 / 10%);
      border: 1px solid $borderColor;
    }
  }
}

.siteLogo {
  border-right: 1px solid $borderColor;
  padding: 5px 20px 5px 0;

  img {
    margin: auto;
    object-fit: contain;
  }
}

.hamberger-menu {
  padding: 5px;
  display: inline-block;

  .bars {
    height: 3px;
    background: $colorGold;
    margin-bottom: 5px;
    @include border-radius(3px);
    display: block;
    width: 20px;
    transition: 0.2s ease-in;

    &.bar2,
    &.bar4 {
      width: 30px;
    }

    &.bar4 {
      margin-bottom: 0;
    }
  }

  &:hover .bars {
    width: 30px;
    background: $siteBlueDark;
  }
}

.sbbellIcon {
  color: $colorGold;

  .MuiBadge-badge {
    @include border-radius(50%);
    height: 24px;
    background-color: $colorGold;
  }
}

.profileDD {
  .profileList {
    max-height: 240px;
    overflow-y: auto;
  }

  .MuiMenu-paper {
    border-radius: 0;

    .MuiMenu-list li {
      width: 100%;
      display: block;
      min-width: 148px;
      p {
        font-size: 16px;
      }
    }

    .MuiMenu-list > li:last-child {
      border-top: 1px solid $borderColor;
    }

    .MuiMenu-list li.active {
      background: $sbBlueLight2;
    }
  }
}

.profileName {
  font-weight: $font-bold;
  background-color: $sbBlueLight2;
  color: $siteBlueDark;
  font-weight: $font-med;
  font-size: $font16;
  padding: 10px 5px 10px 12px;
  display: flex;
  border-radius: 6px;

  span.user_icon {
    margin-right: 10px;
    color: $colorWhite;
    width: 25px;
    height: 25px;
    background-color: $siteBlueDark;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: $font18;
    }
  }
}

.logoutIcon {
  vertical-align: middle;
}

/*======Header css ends ==*/
