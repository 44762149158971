@import '../../../variables.scss';

.Site_wrapper #main-container {
    .ppa_harvestCutReportSummaryPage .warehouse_table{
        th {
            white-space: nowrap;
        }
        tbody tr {
            &:nth-child(even) {
                background-color: $colorWhite;
            }
            &:nth-child(odd) {
                background-color: $tablechildevencolor;
            }

            > td {
                &:first-child {
                    min-width: 175px;
                }
            }
        }
    }
}