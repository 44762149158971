@import '../../../../variables.scss';

.mgmtRoledetail {
  .action-btns {
    width: 100%;
    display: flex;
    justify-content: end;

    .action-groups {
      display: flex;
      gap: 0.7rem;

      .button {
        width: 108px;

        .MuiSvgIcon-root {
          color: #fff;
        }
      }
    }
  }
}

#main-container {
  .mgmt_user_rollpage {
    padding-bottom: 100px;

    .manageuser_clone_content {
      display: flex;
      flex-direction: column;
      gap: 26px;
    }
  }

  .mgmt_table {
    .table_scroll {
      table {
        thead {
          tr {
            th:last-child {
              width: 150px;
            }
          }
        }
      }
    }
  }
}

.mgmt_user_rollpage .warehouse_table .Mui-disabled.MuiButton-contained {
  color: $colorWhite;
}
