@import '../../../variables.scss';

/*========= Component css ========*/
.blueHeadingLabel {    
    label{
         color: $siteBlueDark!important;
    }  
}
.BomPage{

    .sb_bom_Filter{
        .search_filter_button{
            .Mui-disabled {
                color: $colorWhite;
                box-shadow: none;
              }
        }
    }

    .title_content_Box{
        display: flex;
        flex: 1 1;
        justify-content: space-between;
        align-items: center;

        .BOM_panel_Button{
            font-weight: 500;
            font-size: 14px;

            &:hover{
                background-color: transparent;
            }
        }
    }




    .MuiInputBase-input {
        padding: 11px 10px;
    }

    .sb_form_ActionBtn_row{

        &.ReadOnlyRow{

            input[type='number'].Mui-disabled::-webkit-outer-spin-button,
            input[type='number'].Mui-disabled::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
        
            /* Firefox */
            input[type='number'].Mui-disabled {
              appearance: textfield;
              -moz-appearance: textfield;
            }
            
            .sb_AutoSearchDD{

                .Mui-disabled{
                    background-color:#fff;
                    border: 0; 
                    padding-left: 0;                  
                    .MuiAutocomplete-input{
                            padding-left: 0;
                            border:0;
                            color: $bodycolor;                        
                            
                    }                    
        
                }
        
            } 
        
            .Mui-disabled{
                background-color:#fff;
                border: 0;
                padding-left: 0;
        
                .MuiInputBase-input{
                        padding-left: 0;
                        border:0;
                        color: $bodycolor;
                        -webkit-text-fill-color:$bodycolor;
                        font-size: $font16;
                        opacity: 1;
                }

                .MuiOutlinedInput-notchedOutline,fieldset{
                    border: 0;
                    outline: 0;
                }
        
            }            
           
        }
        
    }

    .sb_btn .add-circle-icon {
        font-size: 28px;
      }

    .add_plusTxt_btn{
        &:hover{
            background-color: transparent;
        }
    }


}

// .sb_modal .popup_info {
//     padding-left: 0;
//     padding-right: 0;
//     .popup_txt{
//         max-width: none;
//     }
        
// }


@media (min-width:600px){
 
}

@media (min-width:1024px){

   

}