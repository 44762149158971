@import '../../../../variables.scss';

.typeMaintenanceTabGrid{
    .totCapicityNumber{
        min-width: 200px;
        max-width: 220px;
    }

    

    &.warehouse_table td{
        .dateVal{
            color: $colorOrange;
        }
    }
}