@import '../../../../variables.scss';

.create-role-wrapper {
  .search-filter-dropdown {
    margin-bottom: 18px;
  }

  .add-circle-icon {
    font-size: 28px !important;
  }

  .create-action-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .MuiButton-root {
      width: 120px;
      padding: 8px;
    }

    .MuiButton-root:last-child {
      margin-left: 16px;
    }
  }
}

.create-role-wrapper .expandTrRow {
  border-right-width: 1px;
  border-left-width: 1px;
}
