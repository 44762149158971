@import '../../../../../variables.scss';


.ppa_AddTypeSetup_modal{
   
    .sb_AutoSearchDD > .MuiFormHelperText-root{
     margin: 0;
     margin-block: 0 !important;
     display: inherit;
 }

}
/*==============  Media Queries  ====================*/


@media (min-width:1200px){
    .ppa_AddTypeSetup_modal{

        .sb_modal_form{
            padding-top: 35px;
        }       

        .modal_form_ActionBtn_row {

            label{
                min-height: 24px;
            }
            .custom_label,label{
                display: none;
            }
    
            // &:first-child{
            //     .custom_label,label{
            //         display: inherit;
            //     }
    
            // }

        } 
        

    }
}