@import '../../../variables.scss';

/*========= Component css ========*/

.itemSearchPage{

    .sb_itemSearch_Filter{
        .search_filter_button{
            .Mui-disabled {
                color: $colorWhite;
                box-shadow: none;
              }
        }
    }

    .warehouse_table{
        .common_btn.view_btn{
            background-color: $colorGreenDrak;

            > span {
                display: block;
              }
          
              .MuiSvgIcon-root {
                font-size: 24px;
                line-height: 1;
                margin-top: 6px;
                position: relative;
                color: $colorWhite;
              }
        }
    }
}
