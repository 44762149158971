@import '../../../variables.scss';

/*========== Veriable css=====*/


/*=========Globle  css  ==========*/

.sb_error_Color_txt{
    color: $colorRed;
}



/*========= Component css ========*/

.PPA_EditRow_primalConfirmDialog{
    .MuiDialog-paperFullWidth{
        max-width: 620px;
        .popup_txt{
            max-width: none;
        }
    }
}

.sb_ptc_Filter{
    button.MuiButton-contained.Mui-disabled{
        color: $colorWhite;
    }
}

.Site_wrapper #main-container {
    .primalToCarcassPage{

        .Accr_Content_box{

            .warehouse_table{
                margin: 0;
                box-shadow:none;
                border-radius: 0;

                .body_content{
                    padding: 0;
                }

                .MuiPaper-root.MuiPaper-rounded{
                    border-radius: 0;
                }
            }

            .panel_title{
                display: none;
            }
        }
        
    }

}

.primalToCarcassPage{

    button.p2c_panel_Button{
        background-color: transparent;
    }

    button.sb_btn{
        .add-circle-icon{
            font-size: 28px;
        }
      &.btn_disable{
        color: $colorWhite;
        box-shadow: none;
      }  
    }


    .AccModuleBox{
        .ReadOnlyRow{

            .Mui-disabled{
                /* Chrome, Safari, Edge, Opera */
                input[type=number]::-webkit-outer-spin-button,
                input[type=number]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    appearance: textfield;
                    -moz-appearance: textfield;
                }
            }
                    
            .sb_AutoSearchDD{
    
                .Mui-disabled{
                    background-color:#fff;
                    border: 0;                                    
                    .MuiAutocomplete-input{
                            padding-left: 0;
                            border:0;
                            color: $bodycolor;                        
                            
                    }                    
        
                }
        
            } 
        
            .Mui-disabled{
                background-color:#fff;
                border: 0;
                padding-left: 0;
        
                .MuiInputBase-input{                        
                        border:0;
                        color: $bodycolor;
                        -webkit-text-fill-color:$bodycolor;
                        font-size: $font16;
                        opacity: 1;
                }
    
                .MuiOutlinedInput-notchedOutline,fieldset{
                    border: 0;
                    outline: 0;
                }
        
            }            
           
        }

        .primalCode_tbl_btm_button{
            padding: 15px 20px;
        }

        .Mui-expanded{
            .MuiAccordionSummary-expandIconWrapper{
                .MuiButtonBase-root{
                    transform: rotate(-180deg);
                }
               
            }
        }

     
       .MuiAccordionSummary-expandIconWrapper{
                .MuiButtonBase-root{
                    transform: rotate(-90deg);
                }
       }
       

       .MuiAccordion-root{
                border-radius: 0;
        }

    }

    

    .MuiButtonBase-root.gold_btn{
        background-color:$colorGold;
    
        &:hover,&:focus{
            background-color:$colorGold;
        }
    }
    
    .title_content_Box{
        display: flex;
        flex: 1 1;
        justify-content: space-between;
        align-items: center;

        .p2c_panel_link{
            font-weight: 500;
            font-size: 14px;
        }
    }

    .percent_part{
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        flex-grow: inherit;
        align-content: center;
        align-items: center;

        .edit_btn{
            background-color: $siteBlueLight;
            color: $colorWhite;
            margin-left: 5px;
            margin-right: 5px;
            opacity: 0.3;
            vertical-align: sub;

            &:hover,&.act{
                opacity: 1;
            }
       }

       .del_btn{
        opacity: 0.3;
        background-color: $colorOrange;
        color: $colorWhite;       
        margin-left: 5px;
        margin-right: 0px;

        &:hover,.act{
            opacity: 1;
        }

        .MuiSvgIcon-root{
           font-size: 18px;
        }
   }

        .MuiFormControl-root{
            max-width: 165px;
        }

        .MuiInputBase-input{
            text-align: center;
            
            &.Mui-disabled{
                font-weight: $font-med;
            }
        }
    }
   
}



.Sb_P2CWrap_box{
    border: 1px solid $borderColor;

    .MuiAccordionSummary-content{
        align-items: center;
        padding: 10px 0;
            .CutCol1{
                flex-basis: calc(25% + 40px);
            }
    }

    .title_txt_style{
        font-weight: $font-med;
        color: $colorBlack;
        text-transform: $sbFontAllcaps;

        &.sb_error_Color_txt{
            color: $colorRed;
        }
    }
    .PMCode{
        color: $siteBlueDark;
        font-size: 16px;
        font-weight: $font-med;
        padding-left: 23px;
        word-break: break-word;
    }

    .P2CWrap_Head{
        border-bottom: 1px solid $borderColor;
        padding: 8px 20px 8px 80px;
        display: flex;
       align-items:center;

       .CutCol1{
        flex-basis: 25%;        
       }

       .CutCol2{
        flex-grow:1;
       }

       .edit_btn{
            background-color: $siteBlueLight;
            color: $colorWhite;
            margin-left: 15px;
            opacity: 0.3;
            vertical-align: sub;

            &:hover,&.act{
                opacity: 1;
            }
       }

    }



    .P2CWrap_Foot{
        .add_moreCtrl_row{
            border-top: 1px solid $borderColor;
            padding: 20px 15px 20px 40px;
        }

        .P2CGrid_main_Button{
            border-top: 1px solid $borderColor;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .CutCol1{
                padding-left: 25%;
                justify-content: space-between;
                display: flex;

                @media (max-width:640px){
                    padding-left: 0;
                    padding-bottom: 15px;
                }
            }

            .TotalLabel{
                margin-right: 90px;
                display: inline-block;
            }

            @media (max-width:640px){
                flex-direction: column;
            }


        }

        .sb_error_Color_txt{
            color: $colorRed;
        }

    }

    .MuiAccordionDetails-root{
        padding: 0 0 0 60px;

        .Accr_Content_box{
            border-left: 4px solid #B2AFFF;            
        }

        .MuiTable-root th{
            &:first-child{
                width: 40%;
                min-width: 300px;
            }
        }

    }

    .MuiAccordionSummary-root{
        flex-direction: row-reverse;
    }

    .add_plusTxt_btn{
        &:hover{
            background-color: transparent;
        }
        
    }

}


@media (min-width:600px){
    .sb_ptc_Filter{
        .sb_inline_button{
            padding-top: 35px;
        }
    }
}

@media (min-width:1024px){

    .Sb_P2CWrap_box{

        .PMCode{
            font-size: 17px;
            width: calc(100% - 23px);
        }
    }

}