@import '../../../variables.scss';

.adjust_inventroy_popup .MuiDialog-paper {
  box-shadow: none;
  border: 7px solid rgba(0, 0, 0, 0.6);
  padding-bottom: 70px;
  max-height: 520px;
}

.sb_confirm_dialog .MuiDialog-paper {
  padding-bottom: 0;
}

.adjust_inventroy_popup {
  .production_date {
    padding-top: 15px;
    display: flex;
    flex: 1 1 100% !important;
    margin: 10px 0;

    strong {
      color: #00529b;
    }

    .cal_icon_pop {
      color: #3abde7;
      padding-right: 20px;
    }
  }

  .sb_popup_form > div {
    padding: 0 7px;
    box-sizing: border-box;
    width: calc(50% - 50px);
    @media (max-width: 1199px) {
      width: 50%;
      margin-top: 15px;
    }
  }

  .sb_popup_form {
    display: flex;
    align-items: flex-start;
    margin: 15px -7px 20px -7px;
    flex-wrap: wrap;
    .production_date {
      + div {
        width: 120px;
        @media (max-width: 1199px) {
          width: 50%;
        }
        + div {
          width: 120px;
          @media (max-width: 1199px) {
            width: 50%;
          }
          + div {
            + div {
              width: 225px;
              @media (max-width: 1199px) {
                width: 50%;
              }
            }
          }
        }
      }
    }
    label {
      position: static;
      transform: none;
      padding-bottom: 7px;
      color: #2b2b2b;
    }

    input {
      padding: 11px 10px;
    }

    .MuiAutocomplete-popupIndicator {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAYAAABiS3YzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LmRhYmFjYmIsIDIwMjEvMDQvMTQtMDA6Mzk6NDQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMi41IChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4RTlEMTM1RTI4MzMxMUVEQTVCQUI4NTQwQzk5RThBMiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4RTlEMTM1RjI4MzMxMUVEQTVCQUI4NTQwQzk5RThBMiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjhFOUQxMzVDMjgzMzExRURBNUJBQjg1NDBDOTlFOEEyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjhFOUQxMzVEMjgzMzExRURBNUJBQjg1NDBDOTlFOEEyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+teTMDwAAAINJREFUeNpiPHPmzH8GBgZGY2NjBmoBJgYagFFDqQ9YiFV49uxZJSC1CogrgHgPDmUg+bOkulQQqtEFh4GhpHr/HhC7AvF7LAbDDAT5opNUl6IbHIpuIElhisXg3VADGZANpCT2YQbfQzeQXJciG2wCDQrykhSOAuf9aDYdNRQVAAQYAFoVIC59LKs6AAAAAElFTkSuQmCC)
        no-repeat;
      background-position: top 5px right 10px;
      height: 30px;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      width: 40px;
    }

    fieldset {
      top: 0;
    }

    legend {
      display: none;
    }

    .sb_AutoSearchDD .MuiAutocomplete-root {
      margin-top: 8px;
    }

    .sb_AutoSearchDD .MuiInputBase-root {
      height: 46px;
      overflow: hidden;
    }
  }
}

.title {
  h2 {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 8px;
    color: #2b2b2b;
    font-weight: 500;
  }
}

/*================For new popup style ===*/

body{
  .sb_modalStyle2{
    .MuiDialogContent-root{
      padding: 20px;
      min-height: 150px;
    }
    .MuiDialogTitle-root{
      padding-left: 20px;
      padding-right: 20px;
    }
  }

}  
.sb_modalStyle2{

    .sb_modal_footer{
    padding: 20px;
    border-top:1px solid $borderColor;

    .MuiButtonBase-root{
      &:not(:first-of-type){
        margin-left: 16px;
      }
    }

    .Mui-disabled.MuiButton-contained{
      color: $colorWhite;
    }


  }
}


/*============  Popup Info css start  =========*/

.sb_modal {
  .popup_info {
    text-align: center;
    padding: 20px 16px;

    .popup_icon_outer {
      width: 30px;
      height: 30px;
      background-color: #fff3e5;
      border-radius: 50px;
      padding: 15px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }

    .popup_icon {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 25px;
      height: 25px;
      border-radius: 50px;
      color: #f07641;
      border: 3px solid #f07641;
      margin: 0 auto;
    }

    .popup_txt {
      font-size: 20px;
      font-weight: 500;
      // max-width: 350px;
      margin: 0 auto;
    }

    .popup_checkbox {
      margin-bottom: 1.5rem;
    }

    .popup_button {
      text-align: center;
    }

    .popup_button .yes_btn {
      margin-left: 15px;
    }

    .no_btn {
      background: transparent;
      color: #00529b;
      border: 2px solid #00529b;
    }

    .no_btn:hover {
      color: #fff;
      background: #00529b;
    }
  }
}


/*============  Popup Info css ends  =========*/
