.user_management {
  .MuiGrid-item {
    padding-top: 25px !important;
  }
  .MuiFormControl-root,
  .MuiInputBase-formControl {
    display: flex;
  }
}

.MuiFormControl-root{
    width: 100%;
  }

.inputWithLabel {
  .MuiInputBase-root {
    height: 46px;
  }
}

.MuiFormHelperText-root.Mui-error {
  font-size: 12px !important;
  margin: 0;
  margin-block: 5px;
}

// .Mui-disabled{
//   background-color: #E2E2E2;
// }