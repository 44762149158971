@import '../../../../variables.scss';


/*======== Module  css======*/

.Site_wrapper{
    #main-container{
        .AccModuleBox{
            .MuiAccordionSummary-content{
                margin: inherit;
            }
        }
    }    
}

.AccModuleBox{

    .MuiAccordionSummary-root{
        &.Mui-focusVisible{
                background-color: $colorWhite;
        }
    }	
   
    .MuiAccordionSummary-expandIconWrapper{
            .MuiSvgIcon-root{
                color: $colorBlack;
            }        
    }

    .MuiAccordion-root{

        border-top:1px solid $borderColor;       

        &:first-child{
            border-top:0;
        }

        &::before{
            height: 0.5px;
            background-color: $borderColor;
            display: none;
        }
        &.Mui-expanded{           
            &::before{
                opacity: 1;
            }
        }
    }



}