@import '../../../../variables.scss';

.Site_wrapper #main-container{
    .ppa_DistributionEntryGrid{
        .warehouse_table{
            margin-top: 0;

            .save_btn.Mui-disabled {
                color: $colorWhite;
                box-shadow: none;
              }

             .ppa_EntryGridLastRow{
                background-color: $colorWhite;                

                > td{font-weight: $font-med; height: 62px; text-transform: uppercase;
                    &:nth-child(1),&:nth-child(2){
                        border-right-color: #fff;
  }
                }
            }

            .saveRow_btn.Mui-disabled {
                color: $colorWhite;
                box-shadow: none;
              }

              .table_scroll table{
                min-width: 0;
              }

        }


        .ppa_error_Color_txt{
            color: $colorRed;
        }
        .ppa_Percent100{
            color: $colorGreenDrak;
        }

        .ppa_grid_btm_Btns{
            padding-top: 16px; padding-bottom: 2px;
        }

    }
}

.ppa_DistributionEntryGrid{
    .panel_title{
            h3{
                min-height: 30px;
            }
    }
    .gbl_title_content_Box{
        position: relative;
        z-index: 1;
            .ppa_editLink{
                position: absolute;
                right: 10px;
                top:10px;
                min-width: 0;
                z-index: 1;
            }
    }
    
}

