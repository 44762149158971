@import '../../../../variables.scss';



/*==============  Media Queries  ====================*/


@media (min-width:600px){
    .ppa_editMfg_modal{       

        .sb_modal_form{
            padding-top: 35px;
        }

        .MuiDialog-container{
            .MuiDialog-paper{
                max-width: 800px;
            }
        }

        .modal_form_ActionBtn_row {

            label{
                min-height: 24px;
            }
            .custom_label,label{
                display: none;
            }
    
            // &:first-child{
            //     .custom_label,label{
            //         display: inherit;
            //     }
    
            // }

        } 
        

    }
}