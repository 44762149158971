@import '../../../../../variables.scss';

.ppa_actualTabFilter{
    .ppa_ActualCarcassRow{
        label{
            padding-top: 24px;
        }
       
    }
}
