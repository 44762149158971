@import '../../../variables.scss';

.cmp_editRow_form{

    &.ReadOnlyRow{
            
        .sb_AutoSearchDD{

            .Mui-disabled{
                background-color: #fbfbfb;
                border: 0; 
                padding-left: 0;                  
                .MuiAutocomplete-input{
                        padding-left: 0;
                        border:0;
                        color: $bodycolor;                        
                        
                }                    
    
            }
    
        } 
    
        .Mui-disabled{
            background-color: #fbfbfb;
            border: 0;
            padding-left: 0;
    
            .MuiInputBase-input{
                    padding-left: 0;
                    border:0;
                    color: $bodycolor;
                    -webkit-text-fill-color:$bodycolor;
                    font-size: $font16;
                    opacity: 1;
            }

            .MuiOutlinedInput-notchedOutline,fieldset{
                border: 0;
                outline: 0;
            }

             /* Chrome, Safari, Edge, Opera */
             input[type=number]::-webkit-outer-spin-button,
             input[type=number]::-webkit-inner-spin-button {
             -webkit-appearance: none;
             margin: 0;
             }

             /* Firefox */
             input[type=number] {
                 appearance: textfield;
                 -moz-appearance: textfield;
             }
    
        }            
       
    }

}


.sb_modal_form {
    .modal_form_ActionBtn_row{

        &.ReadOnlyRow{
            
            .sb_AutoSearchDD{

                .Mui-disabled{
                    background-color:#fff;
                    border: 0; 
                    padding-left: 0;                  
                    .MuiAutocomplete-input{
                            padding-left: 0;
                            border:0;
                            color: $bodycolor;                        
                            
                    }                    
        
                }
        
            } 
        
            .Mui-disabled{
                background-color:#fff;
                border: 0;
                padding-left: 0;
        
                .MuiInputBase-input{
                        padding-left: 0;
                        border:0;
                        color: $bodycolor;
                        -webkit-text-fill-color:$bodycolor;
                        font-size: $font16;
                        opacity: 1;
                }

                .MuiOutlinedInput-notchedOutline,fieldset{
                    border: 0;
                    outline: 0;
                }
        
            }            
           
        }
        
    }

}

.Anchor_menu{
    .MuiMenuItem-root{
        .MuiListItemIcon-root{
            justify-content: flex-end;
        }

        .linkIcon{
            opacity: 0;
            color: $siteBlueDark;
            margin-right: 0!important;
            margin-left: 8px;
            align-self: flex-end;
        }

        &:hover{
            .linkIcon{
                opacity: 0;
            }
        }
    }

    &#filter-customized-menu{
        .linkIcon{
            color: $siteBlueDark;
        }
    }

}

.Site_wrapper #main-container{

    .sb_DDTitle_btn.MuiButtonBase-root{        
            padding: 4px 10px;
            position: absolute;
            right: 44px;
            top: 16px;
            z-index: 1;
             
            .MuiSvgIcon-root{
                font-size: 24px;
                border: 0;
            } 
               
    }

    .companyItemPage{

        .expandTrRow{
             
                td{
                    border-top: 2px solid #B2AFFF; 
                    border-right-color:transparent;
                    border-bottom-color: transparent; 
                }                             

                td:first-child{
                    border-left: 1px solid #B2AFFF;
                }

                td:last-child{
                    border-right: 1px solid #B2AFFF;
                    background-color: #fbfbfb;
                    border-bottom-color:#fbfbfb ;
                    position: relative;
                    z-index: 1;
                }
                .icon_grp:before{
                    content:'';
                    display: block;
                    position: absolute;
                    top:-1px;
                    left: 0px;
                    height: 1px;
                    width: 100%;  
                    background-color: #B2AFFF;                  
                }

                .icon_grp:after{
                    content:'';
                    display: block;
                    position: absolute;
                    top:0;
                    right: 0px;
                    height: 105%;
                    width: 4px;  
                    background-color: #B2AFFF;                  
                }
        }

        .ToggleTrRow{
    
            td{
                padding: 0;

                &:first-child{
                    border-left: 1px solid #B2AFFF;
                }

                > div{
                border-bottom: 1px solid #B2AFFF;                
                border-right: 4px solid #B2AFFF;
                padding: 20px 15px;
                background-color: #fbfbfb;
                }   
                
                label{
                    text-align: left;
                    display: block;
                }                
                
            }           

            
        }
    
    }

}

.sb_form label {

    .TooltipIcon{            
        color:#b3cbe1;
        vertical-align: bottom;
        font-size: 20px; 
    }

    &.hasIcon{
        .TooltipIcon{            
            color:#b3cbe1; 
            vertical-align: bottom;
        }
    } 
    
    
    
}


@media (min-width:768px){
    .ppa_editHog_modal{

        .modal_form_ActionBtn_row {
            .custom_label{
                display: none;
            }
    
            &:first-child{
                .custom_label{
                    display: block;
                }
    
            }
        } 

    }
}


@media (min-width:1530px){

    // .ppa_editProdline_modal{

    //     .modal_form_ActionBtn_row {
    //         .custom_label{
    //             display: none;
    //         }
    
    //         &:first-child{
    //             .custom_label{
    //                 display: block;
    //             }
    
    //         }
    //     } 

    // }

    
    
}